import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { DynamicPages } from './dynamicPages'
import { RoutingMiddleware } from 'feature-router'
import { DynamicPagesSymbol } from '@wix/thunderbolt-symbols'
import { DynamicPagesResponseHandlerSymbol } from './symbols'
import { DynamicPagesResponseHandler } from './dynamicPagesResponseHandler'

export const site: ContainerModuleLoader = (bind) => {
	bind(RoutingMiddleware.Dynamic, DynamicPagesSymbol).to(DynamicPages)
	bind(DynamicPagesResponseHandlerSymbol).to(DynamicPagesResponseHandler)
}

export { DynamicPagesResponseHandlerSymbol } from './symbols'
export type { DynamicPagesErrorCallbacksAPI } from './types'
